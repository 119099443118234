$thumb-size: 20px;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;

  &:active {
    cursor: grabbing;
  }
}

// #root {
//   max-width: 500px;
//   padding: 12px;
//   margin: auto;
// }

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: -10px calc(#{$thumb-size} / 2);
  padding-top: 1.6rem;
  height: calc(#{$thumb-size} + 1.6rem);
}

.input-wrapper {
  width: calc(100% + #{$thumb-size});
  margin: 0 calc(#{$thumb-size} / -2);
  position: absolute;
  height: $thumb-size;
}

.control-wrapper {
  width: 100%;
  position: absolute;
  height: $thumb-size;
}

.input {
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  &::-ms-track {
    @include track-styles;
  }

  &::-moz-range-track {
    @include track-styles;
  }

  &:focus::-webkit-slider-runnable-track {
    @include track-styles;
  }

  &::-ms-thumb {
    @include thumb-styles;
  }

  &::-moz-range-thumb {
    @include thumb-styles;
  }

  &::-webkit-slider-thumb {
    @include thumb-styles;
  }
};

.rail {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  border-radius: 3px;
  background: lightgrey;
}

.inner-rail {
  position: absolute;
  height: 100%;
  background: green;
  opacity: 0.5;
}

.control {
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 50%;
  position: absolute;
  background: #f6f8f7;
  border: #50AC97 5px solid;
  top: 50%;
  margin-left: calc(#{$thumb-size} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
}


.input-box {
  display: flex;
  flex-direction: flex-end;
  margin-top: 30px;
  justify-content: flex-end;
  position: relative;
  // top: -10px;
}

.input-box input {
  width: 100px;
  border: none;
}

.input-box p {
  margin-top: 10px;
}

.breakpoint {
  width: $thumb-size/1.5;
  height: $thumb-size/1.5;
  border-radius: 50%;
  position: absolute;
  background: #f6f8f7;
  border: #50AC97 3px solid;
  top: 50%;
  margin-left: calc(#{$thumb-size} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 1;
}

.info-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.names {
  display: flex;
  flex-direction: flex-start;
  margin-top: 0px;
  justify-content: flex-start;
  position: relative;
}

.names h3 {
  margin-top: 50px;
  margin-left: 10px;
  margin-bottom: -100px;
}

.container {
  max-width: 500px;
  padding: 12px;
  margin: auto;
}
.hover-bg-003f4f:hover {
  background-color: #003f4f;
}
