:root { 
--text_error:#F55050;
--blue_gray_50_33:#f0f1f333; 
--blue_gray_50:#f0f1f3; 
--gray_800_02:#424545; 
--gray_800_01:#424445; 
--black_900_3e:#0000003e; 
--green_500:#39c15f; 
--gray_300_99:#dee3e699; 
--teal_300:#50ac97; 
--black_900:#000000; 
--blue_gray_700:#2a6877; 
--blue_gray_900_01:#2f383b; 
--blue_gray_900:#1b424c; 
--gray_600:#7f7a7a; 
--gray_700:#6e6868; 
--gray_600_02:#808180; 
--gray_600_01:#746e6e; 
--blue_gray_100_99:#c8e4dd99; 
--gray_800:#484d4e; 
--gray_900:#212121; 
--blue_gray_100_99_02:#cde4df99; 
--black_900_0c:#0000000c; 
--blue_gray_100_99_01:#cfe4e099; 
--white_A700_01:#fdfdfd; 
--blue_gray_700_7c:#2a68777c; 
--white_A700:#ffffff; 
--hover-bg-003f4f:#003f4f;
  
}