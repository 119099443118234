h1 {
    margin: 0;
    padding: 0;
  }
  html, body, .app {
    
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .popup {
    /* position: fixed; */
    overflow: scroll;
    width: 100%;
    height: 100%;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  .popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
  }